import React, { useState, useEffect } from 'react';
import { 
  LineChart, Line, XAxis, YAxis, CartesianGrid, 
  Tooltip, Legend, ResponsiveContainer, Area, AreaChart 
} from 'recharts';
import './App.css';
import logo from "./logo.svg";

function App() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPrice, setCurrentPrice] = useState(null);
  const [priceChange, setPriceChange] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const priceResponse = await fetch('/api/price');
        const priceData = await priceResponse.json();
        
        const historyResponse = await fetch('/api/history');
        const historyData = await historyResponse.json();
        
        const formattedData = historyData.history.map(item => ({
          time: new Date(item.timestamp).toLocaleTimeString(),
          price: item.price_usd,
          pv: item.price_usd,
        }));
        
        setData(formattedData);
        setCurrentPrice(priceData.price_usd);
        setPriceChange(priceData.price_change);
        setLoading(false);
      } catch (error) {
        console.error('Ошибка получения данных:', error);
        setLoading(false);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 30000);
    return () => clearInterval(interval);
  }, []);

  const getPriceChangeColor = () => {
    if (priceChange === 'up') return '#4CAF50';
    if (priceChange === 'down') return '#F44336';
    return '#9E9E9E';
  };

  if (loading) {
    return <div className="loading-container">Loading...</div>;
  }

  return (
    <div className='App'>
      <div className="dashboard">
      <div className="price-card">
        <h2><img src={logo}/></h2>
        <div className="price-value">
          ${currentPrice}
          <span className="price-change" style={{ color: getPriceChangeColor() }}>
            {priceChange === 'up' && '↑'}
            {priceChange === 'down' && '↓'}
            {priceChange === 'stable' && '→'}
          </span>
        </div>
        <p className="price-label">Live Price</p>
      </div>
      
      <div className="chart-container">
        <ResponsiveContainer width="100%" height={300}>
  <AreaChart 
    data={data}
    margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
  >
    <defs>
      <linearGradient id="colorPrice" x1="0" y1="0" x2="0" y2="1">
        <stop offset="5%" stopColor="#006AFF" stopOpacity={0.8}/>
        <stop offset="95%" stopColor="#006AFF" stopOpacity={0.1}/>
      </linearGradient>
    </defs>
    
    <XAxis dataKey="time" hide={true} />
    
    <YAxis 
      hide={true} 
      domain={['dataMin - 0.001', 'dataMax + 0.001']}
      allowDataOverflow={true}
    />
    
    <Tooltip 
      contentStyle={{ 
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        border: 'none'
      }}
      formatter={(value) => [`$${Number(value).toFixed(6)}`, 'Price, $']}
    />
    
    <Area 
      type="monotone"
      dataKey="price" 
      stroke="#006AFF" 
      strokeWidth={2}
      fillOpacity={1} 
      fill="url(#colorPrice)" 
      activeDot={{ r: 5 }}
      connectNulls={true}
    />
  </AreaChart>
</ResponsiveContainer>

      </div>
    </div>
    </div>
  );
}

export default App;
